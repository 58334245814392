  <template v-slot:node="{ node, collapsed }">
  <fragment>
    <div
      class="rich-media-node"
      :style="{
        border: collapsed ? '2px solid grey' : '',
        backgroundColor: actionColor(node.action),
      }"
      @mouseenter="mouseEnter"
      @mouseleave="mouseLeave"
      @click="
        node.action == 'sold' || node.action == 'loss' ? viewOrder() : () => {}
      "
    >
      <span style="padding: 4px 0; font-weight: bold">
        {{ node.action.capitalizeFirstLetter() }}
      </span>
      <br v-if="node.name" />
      <span v-if="node.name" style="padding: 4px 0; font-weight: bold">
        {{ node.name }}
      </span>
      <br />
      <span style="padding: 4px 0; font-weight: bold">
        {{ node.startAmount }}
        <fragment
          v-if="
            !(
              node.action == 'sold' ||
              node.action == 'loss' ||
              node.action == 'sale' ||
              node.action == 'created'
            )
          "
        >
          ({{ node.amount }})
        </fragment>
        {{ node.unit }}
      </span>
    </div>
    <span v-if="showInfoBox" ref="timeBox" @mouseleave.stop class="timeBox">{{
      formatDateWithTime(node.creation_time)
    }}</span>
  </fragment>
</template>

<script>
import { formatDateWithTime } from '../../../globalFunctions/date'

export default {
  props: ['node', 'collapsed'],
  data() {
    return {
      showInfoBox: false,
    }
  },
  methods: {
    formatDateWithTime,
    viewOrder() {
      this.$router.push({ name: 'Orders', hash: '#' + this.node.ocr })
    },
    mouseEnter() {
      this.showInfoBox = true
    },
    mouseLeave() {
      this.showInfoBox = false
    },
    actionColor(action) {
      switch (action) {
        case 'loss':
          return 'rgb(150, 0, 0)'
        case 'split':
          return 'rgb(96, 122, 181)'
        case 'created':
          return 'rgb(10, 122, 20)'
        case 'sold':
          return 'rgb(10, 122, 20)'
        default:
          break
      }
    },
  },
}
</script>

<style scoped lang="scss">
.timeBox {
  position: absolute;
  background: rgb(102, 102, 102);
  padding: 5px 10px;
  border-radius: 5px;
  left: -50px;
  color: white;
}

.rich-media-node {
  background: #444;
  border-radius: 5px;
  padding: 5px 10px;
  color: white;
}
</style>