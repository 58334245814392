<template>
  <div v-if="batchData">
    <VueTree
      style="width: 100%; height: 100vh"
      :dataset="batchData"
      :config="treeConfig"
    >
      <template v-slot:node="{ node, collapsed }">
        <TreeItem :node="node" :collapsed="collapsed" />
      </template>
    </VueTree>
  </div>
</template>

<script>
import VueTree from '@ssthouse/vue-tree-chart'
import axios from 'axios'
import TreeItem from '../../components/storage/batches/TreeItem.vue'

export default {
  components: {
    VueTree,
    TreeItem
  },
  created() {
    this.getBatchData()
  },
  methods: {
    getBatchData() {
      axios
        .post('storage/getBatchData', {
          batchNumber: this.$route.params.batchId,
        })
        .then((response) => {
          this.batchData = response.data
        })
    },
  },
  data() {
    return {
      batchData: null,
      treeConfig: {
        nodeWidth: 150,
        nodeHeight: 100,
        levelHeight: 130,
      },
    }
  },
}
</script>

<style scoped lang="scss">

</style>