var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[_c('div',{staticClass:"rich-media-node",style:({
      border: _vm.collapsed ? '2px solid grey' : '',
      backgroundColor: _vm.actionColor(_vm.node.action),
    }),on:{"mouseenter":_vm.mouseEnter,"mouseleave":_vm.mouseLeave,"click":function($event){_vm.node.action == 'sold' || _vm.node.action == 'loss' ? _vm.viewOrder() : function () {}}}},[_c('span',{staticStyle:{"padding":"4px 0","font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.node.action.capitalizeFirstLetter())+" ")]),(_vm.node.name)?_c('br'):_vm._e(),(_vm.node.name)?_c('span',{staticStyle:{"padding":"4px 0","font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.node.name)+" ")]):_vm._e(),_c('br'),_c('span',{staticStyle:{"padding":"4px 0","font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.node.startAmount)+" "),(
          !(
            _vm.node.action == 'sold' ||
            _vm.node.action == 'loss' ||
            _vm.node.action == 'sale' ||
            _vm.node.action == 'created'
          )
        )?_c('fragment',[_vm._v(" ("+_vm._s(_vm.node.amount)+") ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.node.unit)+" ")],1)]),(_vm.showInfoBox)?_c('span',{ref:"timeBox",staticClass:"timeBox",on:{"mouseleave":function($event){$event.stopPropagation();}}},[_vm._v(_vm._s(_vm.formatDateWithTime(_vm.node.creation_time)))]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }